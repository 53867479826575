.submit-btn {
  position: relative;
  transition: background-color 0.3s ease;
  min-height: 67px;
}
.submit-btn .text.hidden {
  opacity: 0;
}
.submit-btn .button-state {
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.submit-btn .button-state.fade-enter-active,
.submit-btn .button-state.fade-leave-active {
  transition: opacity 1s ease;
  opacity: 1;
}
.submit-btn .button-state.fade-enter,
.submit-btn .button-state.fade-leave-to {
  opacity: 0;
}
.submit-btn .spinner {
  background-image: url("images/spinner.png");
  animation: rotating 1.5s linear infinite;
}
.submit-btn .checkmark {
  background-image: url("images/submit_success.svg");
  animation-fill-mode: forwards;
  animation: pop 0.5s linear;
}
