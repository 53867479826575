.nav-dropdown {
  width: auto;
}
.dropdown-mobile {
  display: none;
}
.dropdown-desktop {
  display: block;
  margin-left: 25px;
}
.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-item:hover {
  cursor: pointer;
}
.dropdown-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dropdown-wrap a:focus + .dropdown-content-wrap {
  overflow: visible;
  max-height: 600px;
}
.dropdown-wrap .nav-item {
  margin-left: 0 !important;
}
.dropdown-wrap .nav-item:hover .dropdown-content-wrap {
  overflow: visible;
  max-height: 600px;
}
.dropdown-content-wrap {
  max-height: 0px;
  overflow: hidden;
  position: absolute;
  top: 24px;
  left: 50%;
  width: 260px;
  transform: translate(-50%, 0);
}
.dropdown-content-wrap.expand,
.dropdown-content-wrap:focus-within {
  overflow: visible;
  max-height: 600px;
}
.dropdown-container {
  position: relative;
  background-color: #fff;
  padding: 26px;
  width: 100%;
  margin-top: 18px;
  border-radius: 10px;
  box-shadow: 0 0 12px 0px rgba(0,0,0,0.2);
}
.dropdown-container .caret {
  width: 20px;
  border: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  top: -18px;
  left: 125px;
  display: block;
}
.dropdown-list ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.dropdown-list ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.dropdown-list ul li:last-of-type {
  margin-bottom: 0;
}
.link-wrap {
  display: flex;
  width: 100%;
  align-items: flex-end;
}
.link-wrap .nav-item {
  flex: 2;
}
.btn-expand {
  background-color: transparent;
  height: 25px;
  border: none;
  display: flex;
  flex: 1;
  padding: 0;
  border-radius: 0;
  border-bottom: 2px solid tansparent;
  justify-content: flex-end;
  align-items: center;
  outline: none;
  position: relative;
}
.btn-expand:hover {
  border: 0;
  background-color: transparent;
}
.btn-expand:focus:before {
  content: '';
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: -5px;
  right: 0px;
  border-bottom: 2px solid #83b84d;
  z-index: 2;
}
.chevron-icon:after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-image: url("images/chevron.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center right;
  margin-left: 10px;
}
.chevron-icon.chevron-focus-rotate:hover:after,
.chevron-icon.chevron-focus-rotate:focus-within:after {
  transform: rotate(180deg);
}
.chevron-icon.rotate:after {
  transform: rotate(180deg);
}
@media only screen and (max-width: 991px) {
  .nav-dropdown {
    width: 100%;
  }
  .dropdown-mobile {
    display: block;
  }
  .dropdown-desktop {
    display: none;
  }
  .dropdown-wrap {
    background-color: #fff;
  }
  .dropdown-content-wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    transition: 0.6s max-height ease-in-out;
    transform: translate(0, 0);
  }
  .dropdown-content-wrap.expand {
    overflow: hidden;
    max-height: 600px;
  }
  .dropdown-container {
    width: 100%;
    box-shadow: none;
    padding: 10px 0px;
    background-color: transparent;
    margin-top: 3px;
    border-top: 2px solid #ededed;
    border-radius: 0;
  }
  .link-wrap .nav-item .chevron-icon {
    display: none;
  }
}
