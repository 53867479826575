.device {
  width: 22px;
  height: 22px;
  background-image: url("images/devices.svg");
  background-repeat: no-repeat;
  background-size: 132px 22px;
  margin-right: 6px;
}
.device.mac {
  background-position: 0 0;
}
.device.windows {
  background-position: -22px 0px;
}
.device.ios {
  background-position: -44px 0px;
}
.device.android {
  background-position: -66px 0px;
}
.device.browser {
  background-position: -88px 0px;
}
.device.blocker {
  background-position: -110px 0px;
}
