#audit .sm-screen {
  display: none;
}
#audit .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
#audit .copy {
  order: 1;
  max-width: 450px;
  margin-right: 40px;
}
#audit .copy >>> img {
  height: auto;
  max-width: 120px;
  width: 100%;
}
#audit .copy p {
  max-width: 400px;
  margin: auto;
}
#audit .img-holder {
  order: 2;
  max-width: 500px;
}
#audit .img-holder >>> img {
  width: 100%;
  margin: auto;
  margin-left: 30px;
}
@media only screen and (max-width: 767px) {
  #audit .lg-screen {
    display: none;
  }
  #audit .sm-screen {
    display: flex;
  }
  #audit .content {
    flex-direction: column;
    justify-content: center;
  }
  #audit .copy {
    max-width: 330px;
    margin: 30px auto 0;
    order: 2;
  }
  #audit .img-holder {
    order: 1;
  }
  #audit .img-holder >>> img {
    width: 80%;
  }
}
