h1 {
  margin-bottom: 30px;
}
.devices {
  margin: 15px auto 0;
  max-width: 222px;
}
#mobile-hero {
  padding-bottom: 0;
  margin-bottom: 0;
}
#mobile-hero img.mobile {
  display: block;
  margin: auto;
  max-width: 227px;
}
#mobile-content .store {
  margin-bottom: 50px;
}
#mobile-content img.app-store {
  max-width: 254px;
  margin-bottom: 15px;
}
#mobile-content img.google-play {
  max-width: 215px;
  margin-bottom: 15px;
}
#hero {
  padding-top: 60px;
  padding-bottom: 0px;
}
#hero .container {
  max-width: 1100px;
}
#hero .container .os-version {
  margin-top: 6px;
}
#hero .copy {
  margin-bottom: 60px;
}
#hero .copy .different-device {
  margin-top: 20px;
}
#hero .img {
  display: flex;
  align-items: flex-end;
}
#hero .img .dl-bear {
  display: block;
  margin: 16px auto 0;
  width: 250px;
}
#hero .flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#instructions {
  padding-top: 40px;
  padding-bottom: 80px;
}
#instructions .steps {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
#instructions .steps .step {
  width: 23%;
  margin: 10px 1%;
  text-align: center;
}
#instructions .steps .step img {
  max-width: 181px;
  display: block;
  margin: 20px auto;
}
#instructions .steps .step .h6 {
  display: inline-block;
  margin-left: 6px;
  margin-bottom: 10px;
}
#subnotes {
  padding-top: 30px;
  padding-bottom: 30px;
}
#subnotes .notices {
  max-width: 450px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
#subnotes .terms-policy-text {
  text-align: center;
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  #hero .copy {
    margin-bottom: 20px;
    text-align: center;
  }
  #instructions {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 991px) {
  #instructions .steps .step {
    width: 100%;
    max-width: 300px;
  }
}
