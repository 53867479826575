.countries {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.country {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  width: 10px;
}
.country.rows {
  width: 25%;
}
.country.rows.flag-hidden {
  display: none;
}
.country .flag {
  background-image: url("images/countries.png");
  background-size: 20px 735px;
  background-repeat: no-repeat;
  min-width: 20px;
  width: 20px;
  height: 15px;
  margin-right: 5px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .country .flag {
    background-image: url("images/countries@2x.png");
  }
}
.country p {
  margin: 0;
  font-size: 0.777777777777778em;
}
@media only screen and (max-width: 767px) {
  .country {
    margin-bottom: 3px;
  }
  .country.rows {
    width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .country p {
    font-size: 0.666666666666667em;
  }
}
