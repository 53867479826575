.icon-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.icon-desc img {
  max-width: 180px;
  margin-bottom: 20px;
}
.icon-desc p {
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .icon-desc {
    flex-direction: row;
    text-align: left;
    margin-top: 20px;
  }
  .icon-desc img {
    max-width: 100px;
    margin-right: 10px;
    margin-bottom: 0;
  }
}
