#captcha-form {
  width: 100%;
  border: none;
  margin-top: 30px;
  margin-bottom: 50px;
  height: 500px;
}
@media only screen and (max-width: 767px) {
  #captcha-form {
    width: calc(100% + 14px);
    margin-left: -7px;
    margin-right: -7px;
  }
}
