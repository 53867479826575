body .bg-takeover .nav-wrap nav {
  background-color: transparent;
}
body .bg-takeover .nav-wrap:not(.scrolled) nav.tron a {
  color: #fff;
}
body .bg-takeover .nav-wrap:not(.scrolled) nav.tron .btn {
  background-color: #6ec8c4;
  border-color: #6ec8c4;
  color: #000;
}
body .bg-takeover .nav-wrap:not(.scrolled) nav.tron .btn:hover {
  opacity: 0.9;
}
body .bg-takeover .nav-wrap:not(.scrolled) nav.tron a.logo img {
  filter: brightness(200%);
}
body .nav-wrap.scrolled nav {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}
.hello-bar-block {
  position: relative;
  display: block;
  width: 100%;
  padding: 25px 0;
  max-height: 50px;
}
nav {
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f6f6f6;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s ease, background-color 0.3s ease;
}
nav a:not(.btn),
nav button.plain {
  color: #6e6e6e;
  text-decoration: none;
  line-height: 1.6em;
  font-weight: 500;
}
nav a:not(.btn):hover,
nav button.plain:hover,
nav a:not(.btn):focus,
nav button.plain:focus {
  color: #83b84d;
  text-decoration: none !important;
}
nav a:not(.btn).current,
nav button.plain.current {
  font-weight: 600;
  color: #83b84d;
}
nav .current a {
  font-weight: 600;
  color: #83b84d;
}
nav .logo {
  display: block;
  width: 193px;
  line-height: 1em !important;
}
nav .logo img {
  margin-left: 10px;
  width: 100%;
}
nav .nav-inner {
  width: 100%;
  max-width: 1340px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 86px;
}
nav .nav-inner.minimal {
  display: none;
}
nav .top-bar {
  text-align: left;
  width: 260px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
nav .drawer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 0 0 auto;
}
nav .drawer .screen {
  display: none;
}
nav .drawer #nav-items {
  width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin: 0 0 0 auto;
  justify-content: flex-end;
  height: auto;
}
nav .drawer #nav-items .mobile-controls {
  display: none;
  position: relative;
  width: 100%;
}
nav .drawer #nav-items .mobile-controls .close {
  position: relative;
  right: -10px;
  background-image: url("images/close-x.svg");
  background-size: 26px 26px;
  background-position: center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  padding: 3px;
}
nav .drawer #nav-items .mobile-controls .close:hover {
  cursor: pointer;
}
nav .drawer #nav-items .nav-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
nav .drawer #nav-items .nav-items-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
nav .drawer #nav-items .nav-item {
  margin-left: 25px;
  width: auto;
}
nav .drawer .mobile-btn {
  margin-bottom: 0;
}
nav #mobile-menu {
  display: none;
  text-decoration: none;
  background: url("images/hammy.svg");
  background-repeat: none;
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  border-radius: 0;
}
nav #mobile-menu:hover {
  text-decoration: none;
}
.nav-minimal .mobile-cta-nav,
.nav-minimal .nav-inner.complex {
  display: none !important;
}
.nav-minimal nav {
  position: absolute;
  top: 0;
  right: 0;
}
.nav-minimal nav .minimal {
  display: block;
  height: 87px;
}
.nav-minimal nav .minimal .top-bar {
  padding: 15px;
  text-align: center;
  display: block;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-minimal nav .minimal .top-bar.custom-cta {
  justify-content: space-between;
  width: 100%;
}
.mobile-cta-wrap {
  opacity: 0;
  position: fixed;
  z-index: 5;
  top: -61px;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out, opacity 0s linear 0.3s;
}
.mobile-cta-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background: #fff;
  padding: 10px 20px;
  width: 100%;
  border-bottom: 1px solid #ededed;
}
.mobile-cta-nav p {
  font-size: 0.777777777777778em;
  margin: 0;
  color: #6e6e6e;
}
.mobile-cta-nav p:last-of-type {
  font-size: 0.666666666666667em;
}
.mobile-cta-nav .icon {
  width: 40px;
  margin-right: 7px;
}
.mobile-cta-nav .btn {
  margin-left: auto;
}
@media only screen and (max-width: 991px) {
  .hello-bar-block {
    display: none;
  }
  nav {
    position: relative;
  }
  nav .logo img {
    margin-left: 0;
  }
  nav .logo.teams {
    width: 160px;
    margin: auto;
  }
  nav .nav-inner {
    padding: 0;
    justify-content: space-evenly;
    height: auto;
  }
  nav .top-bar {
    width: 100%;
    padding: 16px 20px;
  }
  nav .drawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 0;
    transition-delay: 0.2s;
    transition: max-width 0 ease-in-out;
  }
  nav .drawer .screen {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: -1;
  }
  nav .drawer #nav-items {
    position: absolute;
    top: 0;
    right: -280px;
    overflow: hidden;
    flex-direction: column;
    background: #fff;
    height: 100%;
    padding: 16px 30px 30px;
    max-width: 280px;
    transition: transform 0.2s ease-in-out;
  }
  nav .drawer #nav-items .mobile-controls {
    display: block;
  }
  nav .drawer #nav-items .nav-menu {
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }
  nav .drawer #nav-items .nav-items-wrap {
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 70vh;
  }
  nav .drawer #nav-items .nav-item {
    margin-left: 20px;
  }
  nav .drawer #nav-items .nav-item {
    margin: 15px 0 0 0;
    margin-left: 0 !important;
    width: 100%;
  }
  nav .drawer #nav-items .nav-item.bottom {
    align-self: flex-end;
    margin-top: auto;
  }
  nav .drawer #nav-items .nav-item a {
    display: block;
    width: 100%;
  }
  nav .drawer #nav-items .nav-item a:not(.btn) {
    color: #6e6e6e !important;
  }
  nav .drawer.mobile-open {
    max-width: 100%;
    transition: none;
  }
  nav .drawer.mobile-open .screen {
    display: block;
  }
  nav .drawer.mobile-open #nav-items {
    height: 100%;
    transform: translateX(-280px);
  }
  nav #mobile-menu {
    display: inline-block;
  }
  .nav-minimal nav {
    position: relative;
  }
  .nav-minimal nav .logo {
    width: 193px;
  }
  .nav-minimal nav .minimal {
    height: 61px;
  }
  .mobile-cta-wrap.show {
    opacity: 1;
    transform: translate(0, 61px);
    transition: transform 0.3s ease-in-out, opacity 0s linear 0s;
  }
}
@media only screen and (max-width: 767px) {
  nav .logo {
    width: 35px;
  }
  .nav-minimal nav .minimal .top-bar.custom-cta {
    justify-content: center;
  }
  .nav-minimal nav .minimal .top-bar.custom-cta .custom-cta-link {
    display: none;
  }
}
