.counter:not(.counter-block) .timer {
  display: inline-block;
}
.counter:not(.counter-block) .timer .col {
  display: inline;
  padding-left: 5px;
  padding-right: 5px;
}
.counter:not(.counter-block) .timer .col p {
  display: inline;
  font-size: 0.8em;
}
.counter:not(.counter-block) .timer .col .number {
  font-size: 1em;
  font-weight: bold;
}
.counter.counter-block {
  text-align: center;
  background: #4d4d4d;
  color: #fafafa;
  padding: 25px 0 15px;
  border-radius: 25px;
}
.counter.counter-block .timer {
  padding-top: 20px;
}
.counter.counter-block .number {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 34px;
}
@media only screen and (max-width: 1024px) {
  .counter {
    min-width: 295px;
  }
}
