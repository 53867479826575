p {
  margin: 30px 0;
}
section {
  position: relative;
}
section .anchor {
  position: absolute;
  top: -110px;
}
.pull-quote {
  color: #83b84d;
  font-size: 2em;
  line-height: 1.3em;
  margin: 80px 0;
}
.pull-quote::before {
  content: '\201c';
}
.pull-quote::after {
  content: '\201d';
}
.caption-img {
  margin: 50px auto;
}
.caption-img img {
  margin-bottom: 20px;
}
.caption-img p {
  margin: 20px 0 0;
  font-size: 0.9em;
}
.list {
  margin-top: 30px;
}
>>>.countries-container {
  margin-top: 20px;
}
>>>.countries-container .countries {
  margin-left: 5%;
}
>>>.countries-container .countries .country {
  width: 33%;
  float: left;
  margin-bottom: 3px;
}
#hero {
  padding-bottom: 0px;
}
#hero img {
  display: block;
  margin-top: 30px;
}
#hero h1 span {
  display: block;
  font-size: 0.7em;
}
#contents .table {
  margin-top: 40px;
}
#contents .table a {
  color: #6e6e6e;
  font-weight: 500;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 6px;
  text-decoration: none;
}
#contents .table a:hover,
#contents .table a:focus {
  background-color: #f6f6f6;
  color: #555;
  outline: none;
}
#contents .table a::before {
  content: '';
  display: inline-block;
  position: relative;
  background-image: url("images/down-arrow.svg");
  background-position: center;
  background-size: 20px 20px;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  background-repeat: no-repeat;
  margin-right: 10px;
}
#what .layout-img {
  max-width: 542px;
}
#why {
  padding-bottom: 10px;
}
#reasons .row {
  margin-top: 40px;
}
#how h2 {
  max-width: 550px;
  margin: auto;
  margin-bottom: 30px;
}
#with-tunnelbear .layout-img {
  max-width: 1129px;
  display: block;
  margin: 30px auto 0;
}
#with-tunnelbear .layout-img.desktop {
  display: block;
}
#with-tunnelbear .layout-img.mobile {
  display: none;
  max-width: 361px;
}
#client .pull-quote {
  margin-bottom: 0;
}
#install .btn-wrap {
  margin-top: 40px;
}
#use .layout-img {
  max-width: 520px;
}
#use .caption-img {
  margin-bottom: 0px;
}
#faq p {
  margin: 8px 0;
}
#faq ul.faq {
  max-width: 800px;
  width: 100%;
  list-style-type: none;
  background: #fff;
  padding: 0;
  margin: 50px auto 0;
}
#faq ul.faq > li:nth-of-type(even) {
  padding: 0;
  margin: 0;
}
#tunnelbear img {
  max-width: 1083px;
}
#download img {
  max-width: 120px;
  margin-bottom: 20px;
}
#download a {
  margin-top: 20px;
}
@media only screen and (max-width: 767px) {
  .pull-quote {
    font-size: 1.5em;
    margin: 30px 0 50px;
  }
  .caption-img img {
    margin-bottom: 10px;
  }
  .list {
    margin-top: 15px;
  }
  #reasons .row {
    margin-top: 0;
  }
  #reasons .col {
    margin: 5px 0;
  }
  #with-tunnelbear .layout-img.desktop {
    display: none;
  }
  #with-tunnelbear .layout-img.mobile {
    display: block;
  }
  #install .btn-wrap {
    margin-top: 20px;
  }
  #faq ul.faq {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 991px) {
  >>>.countries-container .countries .country {
    width: 50%;
  }
}
