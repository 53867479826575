#hero {
  padding-bottom: 0;
  overflow: hidden;
}
#hero .strike {
  text-decoration: line-through;
  color: #888;
}
#hero.tron {
  background-color: #000;
  color: #fff;
}
#hero.tron h1,
#hero.tron .h1,
#hero.tron p {
  color: #fff;
}
#hero.tron .btn {
  color: #000;
  background-color: #6ec8c4;
  border-color: #6ec8c4;
}
#hero.tron .btn:hover {
  opacity: 0.9;
}
#hero #trail {
  display: block;
  height: 30.7%;
  width: 400px;
  position: absolute;
  top: 49.2%;
  left: -400px;
  z-index: 0;
  background-image: linear-gradient(to right, #000, #254e4d);
}
#hero #trail.dark {
  background-image: linear-gradient(to right, #000, #1e4140);
  height: 31%;
}
#hero .content {
  max-width: 1000px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
#hero .sale-copy {
  margin-top: 20px;
}
#hero .sale-copy .strike {
  color: #b42025;
  text-decoration: line-through;
}
#hero .copy {
  flex: 1 1 0;
  max-width: 450px;
  position: relative;
  bottom: 70px;
  z-index: 1;
}
#hero .copy .btn-holder {
  display: inline-block;
  margin-top: 15px;
  width: 100%;
}
#hero .img-holder {
  flex: 1 1 0;
  width: 100%;
  max-width: 600px;
  align-self: flex-end;
  position: relative;
}
#hero .img-holder:before {
  top: 0;
  content: "";
  background-color: transparent;
  display: block;
  padding-top: 100%;
  width: 100%;
}
#hero .img-holder .bear video {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  background: transparent url("images/hero-placeholder.png") center bottom no-repeat;
  background-size: 100%;
}
#hero .img-holder .bear .img {
  position: absolute;
  bottom: 0;
  display: block;
}
#hero .img-holder .bear .img.offset-position {
  left: 10%;
}
#hero .img-holder >>>.device {
  display: none;
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 46%;
}
>>>#hero .img-holder >>>.device.ios {
  max-width: 230px;
}
#hero .img-holder >>>.device.android {
  max-width: 227px;
}
#recommended {
  padding-top: 45px;
  padding-bottom: 45px;
}
#recommended .content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
#recommended .content img {
  width: 32%;
  max-width: 160px;
  min-width: 100px;
}
.app-list {
  margin: 80px auto 30px;
  max-width: 280px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.app-list .app {
  flex: 1 1 0;
  text-align: center;
}
.app-list .app p {
  opacity: 0.7;
}
.app-list .app img {
  height: 35px;
  margin-bottom: 6px;
}
#private .content,
#world .content,
#content-list .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
#private .content .img-holder,
#world .content .img-holder,
#content-list .content .img-holder {
  width: 100%;
  flex: 1 1 0;
}
#private .content .img-holder img,
#world .content .img-holder img,
#content-list .content .img-holder img {
  display: block;
  width: 100%;
}
#private .content .copy,
#world .content .copy,
#content-list .content .copy {
  width: 100%;
  flex: 1 1 0;
}
#private .content .copy:last-of-type,
#world .content .copy:last-of-type,
#content-list .content .copy:last-of-type {
  margin-left: 20px;
}
#private .content .copy .img-text,
#world .content .copy .img-text,
#content-list .content .copy .img-text {
  display: flex;
  align-items: flex-start;
  margin: 40px 0;
  max-width: 500px;
}
#private .content .copy .img-text:last-of-type,
#world .content .copy .img-text:last-of-type,
#content-list .content .copy .img-text:last-of-type {
  margin: 0;
}
#private .content .copy .img-text >>> img,
#world .content .copy .img-text >>> img,
#content-list .content .copy .img-text >>> img {
  width: 100px;
  min-width: 100px;
  margin: 0 30px 0 0;
}
#private h2 {
  margin-bottom: 40px;
}
#FAQ ul.faq {
  max-width: 800px;
  width: 100%;
  list-style-type: none;
  border: 1px solid #ddd;
  background: #fff;
  padding: 0;
  margin: 50px auto 0;
}
#FAQ ul.faq > li:nth-of-type(even) {
  padding: 0;
  margin: 0;
}
#private .img-holder {
  order: 2;
}
#private .copy {
  margin-right: 40px;
  order: 1;
}
#world .copy {
  margin-left: 40px;
}
#audit .content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#audit .copy {
  flex: 1 1 0;
  order: 1;
  margin-right: 30px;
}
#audit .copy .copy-inner {
  max-width: 450px;
  margin: auto;
}
#audit .img-holder {
  height: 100%;
  flex: 1 1 0;
  order: 2;
  max-width: 550px;
}
#audit .img-holder img {
  width: 100%;
  margin: auto;
  display: block;
}
#download .copy {
  max-width: 600px;
}
#download .btn,
#download .mobile-btn {
  margin-top: 15px;
}
.btn-mobile {
  max-width: 250px;
  display: block;
  margin: 20px auto 0;
}
@media only screen and (max-width: 767px) {
  #hero .content {
    flex-direction: column;
  }
  #hero .copy {
    flex-basis: auto;
    text-align: center;
    bottom: 0;
    margin-bottom: 40px;
  }
  #hero .img-holder {
    flex-basis: auto;
  }
  #hero .img-holder >>>.device {
    display: block;
  }
  #hero .position .img-holder .bear {
    position: relative;
    left: 10%;
  }
  #recommended {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #recommended .content {
    flex-wrap: wrap;
    justify-content: center;
  }
  #audit .content {
    flex-direction: column;
  }
  #audit .copy {
    flex-basis: auto;
    width: 100%;
    max-width: none;
    margin: 30px auto 0;
    order: 2;
  }
  #audit .copy .copy-inner {
    max-width: none;
  }
  #audit .img-holder {
    flex-basis: auto;
    order: 1;
  }
  #audit .img-holder img {
    width: 80%;
  }
  #download .copy {
    max-width: 350px;
  }
}
@media only screen and (max-width: 575px) {
  #hero .img-holder .bear .img.offset-position {
    width: 80%;
  }
  #hero .img-holder >>>.device {
    width: 42%;
    left: 7%;
  }
  #hero .position .img-holder:before {
    padding-top: 90%;
  }
  #hero .position .img-holder .bear video,
  #hero .position .img-holder .bear .img {
    width: 92%;
  }
}
@media only screen and (max-width: 991px) {
  #private .content,
  #world .content,
  #content-list .content {
    flex-direction: column;
  }
  #private .content .img-holder,
  #world .content .img-holder,
  #content-list .content .img-holder {
    flex-basis: auto;
  }
  #private .content .img-holder img,
  #world .content .img-holder img,
  #content-list .content .img-holder img {
    width: 85%;
    max-width: 350px;
    margin: auto;
  }
  #private .content .copy,
  #world .content .copy,
  #content-list .content .copy {
    flex-basis: auto;
  }
  #private .content .copy .img-text >>> img,
  #world .content .copy .img-text >>> img,
  #content-list .content .copy .img-text >>> img {
    width: 75px;
    min-width: 75px;
  }
  #private .img-holder {
    order: 1;
  }
  #private .copy {
    order: 2;
    margin: 0;
  }
  #world .copy {
    margin: 0;
  }
}
