.input-wrap {
  margin-bottom: 10px;
}
input,
.input,
select {
  position: relative;
  height: 55px;
  padding-top: 26px;
  padding-bottom: 6px;
  line-height: 1.1em;
  font-size: 1em;
  overflow: visible;
  z-index: 2;
  background: rgba(0,0,0,0.03);
  margin-bottom: 0;
}
input#card-element,
.input#card-element,
select#card-element {
  padding-top: 16px;
}
label {
  text-align: left;
  width: 95%;
  position: absolute;
  top: 18px;
  left: 8px;
  font-size: 1em;
  overflow: hidden;
  z-index: 0;
  transition: all, ease-in, 0.3s;
}
label.active {
  top: 8px;
  font-size: 13px;
  line-height: 1.2em;
  z-index: 3;
}
@media only screen and (max-width: 991px) {
  .input-wrap {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 575px) {
  input#card-element,
  .input#card-element,
  select#card-element {
    padding-top: 18px;
  }
}
@media only screen and (max-width: 767px) {
  label.active.cc {
    top: 48px;
  }
}
