.view-wrap section:not(#hero) {
  background-color: #fff;
}
#hero {
  padding-bottom: 0;
}
#hero .section-inner {
  padding-top: 0;
  position: relative;
}
#hero .tagline {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
#hero img.dl-bear {
  display: block;
  margin: 0 auto;
  width: 250px;
}
#hero img.mobile {
  display: block;
  margin: 30px auto 0;
  width: 261px;
}
#downloads {
  padding-bottom: 20px;
}
#downloads .mobile {
  text-align: center;
}
#downloads .mobile img {
  margin: 0 auto 25px;
  display: inline-block;
}
#downloads .mobile img.mobile-btn.google {
  max-width: 215px;
}
#downloads .mobile img.mobile-btn.apple {
  max-width: 254px;
}
#downloads .mobile img.devices {
  margin-top: 10px;
  margin-bottom: 0;
  max-width: 222px;
}
#downloads .mobile .mobile-copy {
  margin-top: 100px;
}
#downloads .download-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  margin-bottom: 30px;
  margin-top: 50px;
}
#downloads .download-row .download-item {
  display: inline-block;
  flex: 1;
  padding: 0 10px;
  max-width: 575px;
  margin-bottom: 10px;
}
#downloads .download-row .btn-holder {
  margin-bottom: 30px;
}
#downloads .copy {
  margin-bottom: 30px;
}
#downloads .btn {
  width: 100%;
  margin-bottom: 6px;
}
#downloads .notices {
  max-width: 450px;
  margin: 60px auto 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
#downloads .terms-policy-text {
  text-align: center;
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  #downloads .download-row {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #downloads .download-row .download-item {
    width: 100%;
    padding: 0;
  }
  #downloads .download-row .btn-holder {
    margin-bottom: 15px;
  }
  #downloads .copy {
    margin-bottom: 20px;
  }
}
