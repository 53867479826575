.view-content {
  display: flex;
  min-height: 80vh !important;
}
.content-horizontal {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: inherit;
  max-height: 980px;
}
.content-horizontal .horizontal-inner {
  width: 100%;
}
.container {
  max-width: 100%;
  width: 100%;
  padding: 0 25px;
}
#top-ribbon {
  padding-top: 0;
  padding-bottom: 0;
  height: 0px;
  position: relative;
}
#top-ribbon .section-inner {
  overflow: hidden;
}
#top-ribbon .floating-title {
  padding-top: 4%;
  position: relative;
  left: 15%;
  z-index: 2;
  width: 100%;
}
#top-ribbon .floating-title h1 {
  padding: 0 22px;
  margin: auto;
  text-align: left;
}
#bear-images {
  padding: 0;
  position: relative;
  z-index: 2;
}
#bear-images .bears {
  display: flex;
  align-items: flex-end;
}
#bear-images .bear-pic {
  flex: 1 1 0;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  margin-top: auto;
}
#bear-images .bear-pic.item-yearly:before {
  display: block;
  top: 0;
  content: "";
  background-color: transparent;
  height: 0;
  padding-top: 78%;
  width: 100%;
}
#bear-images .bear-pic img.visible-xs {
  display: none;
}
#bear-images .bear-pic img.hidden-xs {
  display: block;
}
#bear-images .bear-pic img.free {
  width: 26%;
  max-width: 80px;
  margin: 0 auto 0px;
}
#bear-images .bear-pic img.monthly {
  width: 55%;
  max-width: 170px;
  margin: 0 auto 0;
}
#bear-images .bear-pic img.yearly {
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 0 auto -38%;
  position: absolute;
  top: 0;
  left: 0;
}
#bear-plans {
  background-color: #fff;
  text-align: center;
  padding: 0 0 70px;
  position: relative;
  overflow: visible;
}
#bear-plans .content {
  padding-top: 5px;
  max-width: 250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
}
#bear-plans .item-column {
  position: relative;
  z-index: 3;
}
#bear-plans .save-circle {
  position: absolute;
  right: 12%;
  top: -30px;
  background-color: #6e6e6e;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#bear-plans .save-circle .save {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  line-height: 1em;
  margin: 0;
  transform: rotate(15deg);
}
#bear-plans h2 {
  order: 1;
  margin-bottom: 20px;
  font-weight: 700;
}
#bear-plans p.copy {
  margin-bottom: 15px;
}
#bear-plans .copy {
  order: 2;
}
#bear-plans ul {
  text-align: left;
  margin: 0 auto;
}
#bear-plans .pricing {
  order: 3;
  margin: 15px 0 10px;
}
#bear-plans .pricing .amount {
  font-size: 1.8em;
  font-weight: 600;
}
#bear-plans .btn {
  width: 100%;
  display: block;
  align-self: flex-end;
  margin: auto auto 0;
  order: 4;
}
#bear-plans .payment-info {
  padding: 30px 15px;
}
#bear-plans .payment-info img.payment-img {
  width: 100%;
  max-width: 253px;
  display: block;
  margin: 10px auto;
}
/* ---------- Mobile Selections ---------- */
.mobile-menu {
  width: 95%;
  max-width: 360px;
  margin: 15px auto 10px;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #6e6e6e;
  display: none;
}
.mobile-menu button {
  width: 33.3333%;
  padding: 10px;
  border-radius: 0;
  border: none;
  background-color: transparent;
  color: #6e6e6e;
  border-right: 1px solid #6e6e6e;
  outline: 0;
}
.mobile-menu button:focus {
  outline: 0;
}
.mobile-menu button:last-of-type:not(.selected) {
  border-right: none;
}
.mobile-menu button.selected {
  background-color: #6e6e6e;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  #top-ribbon {
    height: auto;
    padding-bottom: 15px;
  }
  #top-ribbon .floating-title {
    padding-top: 10px;
    left: auto;
  }
  #top-ribbon .floating-title h1 {
    text-align: center;
    font-size: 28px;
  }
  #bear-images .bear-pic.item-yearly:before {
    display: none;
  }
  #bear-images .bear-pic img.visible-xs {
    display: block;
  }
  #bear-images .bear-pic img.hidden-xs {
    display: none;
  }
  #bear-images .bear-pic img.free,
  #bear-images .bear-pic img.monthly,
  #bear-images .bear-pic img.yearly {
    position: relative;
    width: auto;
    max-width: 100%;
    height: 260px;
    margin-bottom: -75px;
  }
  #bear-plans {
    padding-bottom: 40px;
  }
  #bear-plans .content {
    padding-top: 0;
  }
  #bear-plans .save-circle {
    display: none;
  }
  #bear-plans h2 {
    display: none;
  }
  #bear-plans ul {
    order: 3;
  }
  #bear-plans .pricing {
    order: 2;
    margin-top: 0px;
  }
  #bear-plans .pricing .amount {
    font-size: 1.6em;
  }
  #bear-plans .btn {
    max-width: 200px;
  }
  #bear-images .select-free .item-monthly,
  #bear-plans .select-free .item-monthly {
    display: none;
  }
  #bear-images .select-free .item-yearly,
  #bear-plans .select-free .item-yearly {
    display: none;
  }
  #bear-images .select-free p.copy,
  #bear-plans .select-free p.copy {
    margin-top: 15px;
  }
  #bear-images .select-free .btn,
  #bear-plans .select-free .btn {
    margin-top: 40px;
  }
  #bear-images .select-monthly .item-free,
  #bear-plans .select-monthly .item-free {
    display: none;
  }
  #bear-images .select-monthly .item-yearly,
  #bear-plans .select-monthly .item-yearly {
    display: none;
  }
  #bear-images .select-yearly .item-free,
  #bear-plans .select-yearly .item-free {
    display: none;
  }
  #bear-images .select-yearly .item-monthly,
  #bear-plans .select-yearly .item-monthly {
    display: none;
  }
  .mobile-menu {
    display: flex;
  }
}
@media only screen and (max-width: 991px) {
  #bear-plans .save-circle {
    width: 60px;
    height: 60px;
  }
  #bear-plans .save-circle .save {
    font-size: 18px;
  }
}
