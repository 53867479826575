.testimony {
  display: flex;
  max-width: 1200px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 30px rgba(0,0,0,0.12);
  border-radius: 8px;
  align-items: center;
  padding: 35px 50px;
}
.testimony img {
  max-width: 136px;
  width: 100%;
  margin-right: 20px;
}
.testimony blockquote {
  margin: auto;
  max-width: 800px;
}
.testimony blockquote .quote {
  margin-bottom: 15px;
}
.testimony blockquote .quote:before {
  background: url("images/left-quote.svg");
  margin-right: 5px;
}
.testimony blockquote .quote:after {
  background: url("images/right-quote.svg");
  margin-left: 5px;
  margin-right: -15px;
}
.testimony blockquote .quote:before,
.testimony blockquote .quote:after {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
}
@media only screen and (max-width: 767px) {
  .testimony {
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
  }
  .testimony img {
    margin: auto;
    margin-bottom: 20px;
  }
}
