.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.banner-wrap {
  opacity: 0;
}
.banner-wrap.visible {
  opacity: 1;
  width: 100%;
  position: fixed;
  z-index: 10000;
  bottom: 0;
  background: #ededed;
  border-top: 1px solid #fff;
}
.banner-content {
  display: flex;
  align-items: center;
  width: 900px;
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
  opacity: 1;
}
.banner-content .text-img {
  display: flex;
  align-items: center;
}
.banner-content .txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banner-content img {
  width: 50px;
  margin-right: 20px;
  min-width: 50px;
}
.banner-content p {
  margin: 0 20px 0 0;
  font-weight: 500;
  display: inline-block;
}
.banner-content button {
  margin-top: 10px;
  white-space: nowrap;
}
@media only screen and (max-width: 575px) {
  .banner-content {
    padding: 10px 5px;
  }
  .banner-content .text-img {
    align-items: start;
  }
  .banner-content .text-img img {
    display: none;
  }
  .banner-content .txt {
    display: block;
  }
  .banner-content img {
    margin-top: 10px;
  }
  .banner-content p {
    margin-right: 0;
    font-size: 12px;
  }
  .banner-content button {
    padding: 5px 20px;
    float: right;
  }
}
