button {
  width: 100%;
  margin: 5px auto 10px;
}
.age-consent-wrapper {
  margin: 10px 10px 5px 15px;
}
.age-consent-wrapper .checkbox {
  font-size: 15px;
  color: #1a1a1a;
}
.age-consent-wrapper .checkbox .check {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 5px;
}
.age-consent-wrapper .checkbox input:checked ~ .check {
  background-image: url("~@/images/age_consent_checkbox.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
  background-color: #83b84d;
  border: none;
}
