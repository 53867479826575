@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

@keyframes slideInDown
  0%
    transform: translate(80px,  -80px)
    opacity: 0

  50%
    opacity: 1

  100%
    transform: translate(0, 0)
    opacity: 1

#hello-bar
  padding: 8px 20px
  border-bottom: 2px solid $accent
  overflow: hidden
  box-shadow: 0px 2px 7px rgba(0, 0, 0, .15)
  width: 100%

  .hello-bar-inner
    position: relative
    width: 100%
    max-width: 1340px
    display: flex
    flex-wrap: wrap
    flex-direction: row
    align-content: center
    justify-content: center
    margin: auto

  .content
    display: flex
    position: relative
    align-items: center

  .content-left
    margin-right: 10px

    +media('xs')
      margin: 0
      font-size: 0.9em

  .content-right
    min-width: 444px
    margin-left: 10px

    +media('sm')
      min-width: auto
      margin-left: 0

  p
    color: $dark-grey
    margin: auto 10px

  .btn-wrap
    position: relative

    .paw
      position: absolute
      top: -12px
      right: -83px
      max-width: 90px
      transform: translate(-80px,  -80px)
      animation-name: slideInDown
      animation-delay: 2s
      animation-duration: 1s
      animation-fill-mode: forwards

    .btn 
      padding: 5px 12px
      min-width: 6.3em

      +media('xs')
        font-size: 0.9em
        min-width: 5em

  .lights
    display: block
    background-image: url('./images/lights_sprite.png')
    background-size: 292px 80px
    background-position: 0 0
    position: absolute
    top: -25px
    right: -128px
    width: 146px
    height: 80px

    +media('md')
      display: none

    &.off
      background-position: -146px 0


dump_media_cache()
