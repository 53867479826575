.view-wrap {
  padding-top: 0;
  background-color: #ededed;
}
#footer .container {
  width: 100%;
  max-width: 850px;
}
#footer a,
#footer p,
#footer li {
  font-size: 14px;
}
#footer img {
  max-width: 164px;
}
#footer h6 {
  font-size: 16px;
  color: #6e6e6e;
  text-transform: uppercase;
}
#footer .footer-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#footer .footer-column {
  text-align: left;
  margin-bottom: 25px;
  padding-right: 10px;
  width: auto;
  min-width: 150px;
  max-width: 200px;
}
#footer .footer-column .coming-soon h6 {
  color: #ededed;
}
#footer .footer-column ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#footer .footer-column ul.horizontal {
  display: flex;
  flex-direction: row;
}
#footer .footer-column ul li {
  margin-top: 0.8em;
}
#footer .footer-column ul li a {
  color: #6e6e6e;
}
#footer .footer-column ul li a.icon-parent {
  opacity: 1;
  margin-right: 15px;
}
#footer .footer-column ul li a.icon-parent:hover {
  opacity: 0.9;
  text-decoration: none;
}
#footer .footer-column ul li a.icon-parent .icon {
  height: 18px;
}
#footer .footer-column ul li a.icon-parent .icon-instagram {
  height: 19px;
}
#footer #essentials {
  margin-top: 50px;
}
#footer #essentials .apps {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
#footer #essentials .apps .app {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}
#footer #essentials .apps .app img {
  height: 50px;
  width: 50px;
  margin: 10px auto;
}
#footer #signature {
  padding-top: 130px;
}
#footer #signature img {
  opacity: 0.7;
  margin-bottom: 15px;
}
#footer .minimal {
  padding: 20px 0;
}
#footer .minimal p {
  display: inline-block;
  margin: auto;
}
#footer .pi {
  font-size: 10px;
  position: fixed;
  bottom: 10px;
  right: 18px;
}
#footer .pi-video {
  background-image: url("https://s3.amazonaws.com/tunnelbear/images/pi3.gif");
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 101;
}
@media only screen and (max-width: 767px) {
  #footer .footer-column {
    min-width: 100px;
    max-width: 120px;
  }
  #footer .pi {
    font-size: 9px;
  }
}
