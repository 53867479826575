.container {
  margin: 0;
  display: block;
  padding: 0;
}
.container p {
  margin: 0;
}
.container .expanded button.faq-title.plain {
  background: #f5f5f5 !important;
}
.container button.faq-title.plain {
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: space-between;
  background: #fff;
  border-radius: 0;
  padding: 20px;
  width: 100%;
  text-decoration: none;
}
.container button.faq-title.plain:hover,
.container button.faq-title.plain:focus {
  text-decoration: none;
  background: #fafafa;
}
.container button.faq-title.plain:focus {
  outline: none;
  text-decoration: none;
}
.container button.faq-title.plain img {
  display: block;
  width: 20px;
  margin-left: 10px;
  transition: transform 0.3s ease-out;
  align-self: baseline;
}
.container button.faq-title.plain img.expanded {
  transform: rotate(45deg);
}
.container .faq-content {
  background: #fff;
  padding: 20px 50px 20px 20px;
  border-bottom: 1px solid #f6f6f6;
}
.container .faq-content >>> ul {
  margin: 0;
  padding-left: 20px;
}
.container .faq-content >>> ul li {
  margin: 10px 0;
  list-style-type: disc;
}
.container .slide-fade-enter-active {
  transition: all 0.3s ease;
}
.container .slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.container .slide-fade-enter,
.container .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
