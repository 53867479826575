#hello-bar {
  padding: 8px 20px;
  border-bottom: 2px solid #83b84d;
  overflow: hidden;
  box-shadow: 0px 2px 7px rgba(0,0,0,0.15);
  width: 100%;
}
#hello-bar .hello-bar-inner {
  position: relative;
  width: 100%;
  max-width: 1340px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin: auto;
}
#hello-bar .content {
  display: flex;
  position: relative;
  align-items: center;
}
#hello-bar .content-left {
  margin-right: 10px;
}
#hello-bar .content-right {
  min-width: 444px;
  margin-left: 10px;
}
#hello-bar p {
  color: #555;
  margin: auto 10px;
}
#hello-bar .btn-wrap {
  position: relative;
}
#hello-bar .btn-wrap .paw {
  position: absolute;
  top: -12px;
  right: -83px;
  max-width: 90px;
  transform: translate(-80px, -80px);
  animation-name: slideInDown;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
#hello-bar .btn-wrap .btn {
  padding: 5px 12px;
  min-width: 6.3em;
}
#hello-bar .lights {
  display: block;
  background-image: url("images/lights_sprite.png");
  background-size: 292px 80px;
  background-position: 0 0;
  position: absolute;
  top: -25px;
  right: -128px;
  width: 146px;
  height: 80px;
}
#hello-bar .lights.off {
  background-position: -146px 0;
}
@media only screen and (max-width: 575px) {
  #hello-bar .content-left {
    margin: 0;
    font-size: 0.9em;
  }
  #hello-bar .btn-wrap .btn {
    font-size: 0.9em;
    min-width: 5em;
  }
}
@media only screen and (max-width: 767px) {
  #hello-bar .content-right {
    min-width: auto;
    margin-left: 0;
  }
}
@media only screen and (max-width: 991px) {
  #hello-bar .lights {
    display: none;
  }
}
@-moz-keyframes slideInDown {
  0% {
    transform: translate(80px, -80px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slideInDown {
  0% {
    transform: translate(80px, -80px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slideInDown {
  0% {
    transform: translate(80px, -80px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slideInDown {
  0% {
    transform: translate(80px, -80px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
