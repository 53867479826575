.chat-icon-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 70px;
  height: 70px;
  margin: 20px;
}
.ai-chat-bot-container {
  height: 0px;
  max-height: 100vh;
  border-radius: 10px;
  background-color: #fff;
  max-width: 430px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  overflow: hidden;
  box-shadow: 5px 0 20px 5px rgba(0,0,0,0.1);
  margin: 20px;
  z-index: 9999;
}
.ai-chat-bot-container.show-chat {
  height: 550px;
  animation: openChatAni 0.25s ease-in;
}
.ai-chat-bot-container.hide-chat {
  height: 0px;
  animation: closeChatAni 0.25s ease-out;
}
.ai-chat-bot-container .message-container {
  height: 100%;
  flex-direction: column;
  position: relative;
  width: 100%;
  display: flex;
}
.ai-chat-bot-container .message-container .message-header {
  display: flex;
  height: 60px;
  background-color: #83b84d;
  justify-content: flex-start;
  position: relative;
  flex-direction: row;
}
.ai-chat-bot-container .message-container .message-header .minimize-container {
  flex: 0 1 auto;
  margin-left: auto;
  cursor: pointer;
  height: inherit;
}
.ai-chat-bot-container .message-container .message-header .minimize-container .minimize-btn {
  height: inherit;
  padding: 10px;
}
.ai-chat-bot-container .message-container .message-header .logo-container {
  height: inherit;
  flex: 0 1 auto;
  flex-direction: row;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ai-chat-bot-container .message-container .message-header .logo-container .logo {
  height: inherit;
  width: 115px;
}
.ai-chat-bot-container .message-container .message-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 80px;
}
.ai-chat-bot-container .message-container .message-content .messages {
  display: flex;
  flex-direction: columns;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  position: relative;
  height: 100%;
  overscroll-behavior: contain;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .fine-print {
  float: left;
  text-align: end;
  color: #888;
  font-size: 10px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .small {
  font-size: 12.5px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .welcome-msg-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 16px;
  padding: 12px 16px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .welcome-msg-container .avtr {
  width: auto;
  margin-bottom: 0;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .avtr {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .avtr .bot-res-img {
  width: 24px;
  height: 24px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .avtr p {
  font-size: 11px;
  margin-bottom: 0;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .avtr .welcome-msg-avtr {
  width: 80px;
  margin: auto;
  height: auto;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai p {
  margin: 0;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai.welcome-msg {
  flex-basis: 70%;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai.welcome-msg p {
  font-size: 12px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai.welcome-msg .hello-txt {
  color: #888;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai.welcome-msg .info-txt {
  color: #555;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .typing-animation {
  text-align: center;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .typing-animation span {
  width: 5px;
  height: 5px;
  background-color: #83b84d;
  display: inline-block;
  margin: 1px;
  border-radius: 50%;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .typing-animation span:nth-child(1) {
  animation: bounce 1s infinite;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .typing-animation span:nth-child(2) {
  animation: bounce 1s infinite 0.2s;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .typing-animation span:nth-child(3) {
  animation: bounce 1s infinite 0.4s;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .chat-bubble {
  border: 1px solid #83b84d;
  position: relative;
  color: #6e6e6e;
  padding: 10px;
  border-radius: 10px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .chat-bubble::before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #83b84d;
  left: 10px;
  top: -20px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .chat-bubble::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #fff;
  left: 10px;
  top: -18px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .res-txt-bot {
  border: 1px solid #83b84d;
  color: #6e6e6e;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .res-txt-bot ul {
  display: block;
  list-style-type: disc;
  padding-inline-start: 10px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-ai-container .msg-ai .res-txt-bot ol {
  display: block;
  list-style-type: decimal;
  padding-inline-start: 10px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-user {
  background-color: #ededed;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  float: right;
  width: 70%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.ai-chat-bot-container .message-container .message-content .messages /deep/ .msg-user .res-txt-user {
  width: 100%;
}
.ai-chat-bot-container .message-container .message-content #msg_form .input-container {
  position: relative;
  border-top: 1px solid #ededed;
  padding: 15px;
  width: 100%;
  display: flex;
}
.ai-chat-bot-container .message-container .message-content #msg_form .input-container input {
  border: none;
  outline: none;
  resize: none;
  min-height: 20px !important;
  padding-right: 15px;
  width: 90%;
  font-size: 14px;
  font-family: 'Montserrat';
}
.ai-chat-bot-container .message-container .message-content #msg_form .send-msg {
  cursor: pointer;
  width: 32px;
  height: 32px;
  float: right;
  padding: 1px;
  border: none;
  background-color: transparent;
}
.mleft-15 {
  margin-left: 15px;
}
.mtop-5 {
  margin-top: 5px;
}
@media only screen and (max-width: 767px) {
  .ai-chat-bot-container {
    width: auto;
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-o-keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes openChatAni {
  from {
    height: 0px;
  }
  to {
    height: 550px;
  }
}
@-webkit-keyframes openChatAni {
  from {
    height: 0px;
  }
  to {
    height: 550px;
  }
}
@-o-keyframes openChatAni {
  from {
    height: 0px;
  }
  to {
    height: 550px;
  }
}
@keyframes openChatAni {
  from {
    height: 0px;
  }
  to {
    height: 550px;
  }
}
@-moz-keyframes closeChatAni {
  from {
    height: 550px;
  }
  to {
    height: 0px;
  }
}
@-webkit-keyframes closeChatAni {
  from {
    height: 550px;
  }
  to {
    height: 0px;
  }
}
@-o-keyframes closeChatAni {
  from {
    height: 550px;
  }
  to {
    height: 0px;
  }
}
@keyframes closeChatAni {
  from {
    height: 550px;
  }
  to {
    height: 0px;
  }
}
