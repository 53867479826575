#network p {
  padding-bottom: 1em;
}
#network img {
  display: block;
  max-width: 464px;
  padding-right: 10px;
}
#network .countries-container {
  width: 100%;
  margin: auto;
}
@media only screen and (max-width: 991px) {
  #network img {
    margin-bottom: 30px;
    padding-right: 0;
    max-width: 100%;
    width: 100%;
  }
  #network .text {
    max-width: 100%;
  }
  #network .world {
    max-width: 400px;
    margin: 0 auto 20px;
  }
}
@media only screen and (max-width: 767px) {
  #network .countries-container {
    width: 100% !important;
  }
}
