.view-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: start;
  padding-top: 0;
}
.view-wrap.fade-enter-active,
.view-wrap.fade-leave-active,
.view-wrap.minimize-enter-active,
.view-wrap.minimize-leave-active {
  transition: opacity 0.2s ease;
  opacity: 1;
}
.view-wrap.fade-enter,
.view-wrap.fade-leave-to,
.view-wrap.minimize-enter,
.view-wrap.minimize-leave-to {
  transition: opacity 0.2s ease;
  opacity: 0;
}
.view-wrap .grey-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 1;
  background: rgba(0,0,0,0.5);
}
.view-wrap .modal-wrap {
  width: 100%;
  max-width: 550px;
  text-align: center;
  margin: 20vh auto 0;
  max-height: 75vh;
  overflow-y: auto;
}
.view-wrap .modal-wrap.large {
  max-width: 650px;
}
.view-wrap .modal-wrap.xl {
  max-width: 790px;
}
.view-wrap .modal-wrap.overlay {
  max-width: 1200px;
}
.view-wrap .modal-wrap.overlay .form-wrap {
  background-color: #000;
}
.view-wrap .modal-wrap.overlay .form-wrap .form {
  padding-bottom: 0;
}
.view-wrap .form-wrap {
  display: block;
  margin: 0 auto;
  z-index: 1;
  width: 95%;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 3px;
}
.view-wrap .form-wrap .form {
  margin: 0px auto 0px;
  padding: 30px 50px 35px;
}
.view-wrap .close {
  position: absolute;
  top: 15px;
  right: 15px;
  background-image: url("images/close-x.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  z-index: 3;
}
.view-wrap .close:hover {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .view-wrap {
    align-items: center;
  }
  .view-wrap .form-wrap .form {
    padding: 30px 7%;
  }
}
@media only screen and (min-width: 768px) {
  .view-wrap.minimize-leave-active {
    transition-delay: 1s;
  }
  .view-wrap.minimize-leave-active .grey-bg {
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
  .view-wrap.minimize-leave-active .overlay {
    transition: width 1.2s ease-in-out, transform 0.7s ease-in-out 0.5s, opacity 1s ease-in-out 1s;
    opacity: 0;
    transform: translate(-84vw, 100vh) scale(-1);
  }
}
@media only screen and (max-width: 991px) {
  .view-wrap .modal-wrap {
    margin: auto;
    max-height: 94vh;
  }
}
