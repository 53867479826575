h1 {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}
#bear-plans {
  background-color: #fff;
  text-align: center;
  padding-bottom: 70px;
  position: relative;
}
#bear-plans.free-instead .item-free:before {
  display: none;
}
#bear-plans.free-instead .item-column.item-free .inner {
  padding-top: 40px;
}
#bear-plans.free-instead .item-column.item-free .content {
  padding-top: 5px;
  max-width: 70%;
}
#bear-plans.free-instead .item-column.item-free .content h2 {
  font-size: 1.4em;
}
#bear-plans.free-instead .country-india-container .bear-img-wrap {
  bottom: -70px !important;
}
#bear-plans.free-instead .country-india-container .inner {
  padding-bottom: 15px;
}
#bear-plans.free-instead .country-india-container .inner h2 {
  margin-bottom: 0;
}
#bear-plans.free-instead .country-india-container .inner .read-more-btn {
  margin: 0 auto;
}
#bear-plans .plans {
  display: flex;
  margin: 0 auto;
  max-width: 1000px;
}
#bear-plans .bear-pic {
  position: relative;
  height: 100px;
  z-index: 9;
}
#bear-plans .bear-pic h2 {
  position: relative;
  z-index: 1;
  bottom: -200px;
}
#bear-plans .bear-pic .bear-img-wrap {
  width: 100%;
  position: relative;
}
#bear-plans .bear-pic .bear-img-wrap img {
  margin-left: auto;
  margin-right: auto;
}
#bear-plans .bear-pic .bear-img-wrap.free {
  margin-top: 130px;
  position: absolute;
}
#bear-plans .bear-pic .bear-img-wrap.free img {
  max-width: 70px;
  margin-right: -15px;
}
#bear-plans .bear-pic .bear-img-wrap.free img.small {
  display: none;
}
#bear-plans .bear-pic .bear-img-wrap.unlimited {
  margin-top: -100px;
  position: absolute;
}
#bear-plans .bear-pic .bear-img-wrap.unlimited img {
  display: block;
  max-width: 390px;
  margin-left: -15px;
}
#bear-plans .bear-pic .bear-img-wrap.unlimited img.summer-sale {
  height: 335px;
}
#bear-plans .bear-pic .bear-img-wrap.stop-censorship {
  position: absolute;
  bottom: -15px;
}
#bear-plans .bear-pic .bear-img-wrap.stop-censorship img {
  display: block;
  max-width: 287px;
  margin-top: 10px;
  margin: 0 auto;
}
#bear-plans .bear-pic .bear-img-wrap.teams {
  margin-top: -65px;
  position: absolute;
}
#bear-plans .bear-pic .bear-img-wrap.teams img {
  max-width: 250px;
}
#bear-plans .bear-pic .bear-img-wrap img {
  width: 100%;
  margin: 0 auto;
}
#bear-plans .item-column {
  flex: 1 1 0;
  position: relative;
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
}
#bear-plans .item-column .inner-wrap {
  display: flex;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}
#bear-plans .item-column.item-free:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 55px;
  width: 1px;
  background: #ededed;
  height: 71%;
}
#bear-plans .item-column.item-unlimited h2.spring-sale:after {
  align-items: center;
  background: #7c807f;
  border-radius: 100%;
  box-sizing: border-box;
  color: #fff;
  content: "67% OFF";
  display: flex;
  font-size: 1.15rem;
  font-style: italic;
  font-weight: 900;
  height: 3.6em;
  line-height: 1;
  padding: 0.1em 0 0 0;
  position: absolute;
  right: 0.6em;
  text-align: center;
  top: -2.5em;
  transform: rotate(12deg);
  width: 3.6em;
}
#bear-plans .item-column.item-unlimited .inner {
  position: relative;
  z-index: 1;
}
#bear-plans .item-column.item-teams {
  margin-left: 20px;
}
#bear-plans .item-column.item-teams .inner-wrap {
  overflow: visible;
}
#bear-plans .item-column .inner {
  padding-top: 130px;
  display: flex;
  width: 100%;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 40px;
  margin-right: 0;
  border-radius: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: -1px 3px 20px rgba(0,0,0,0.12);
}
#bear-plans .item-column .content {
  padding-top: 5px;
  max-width: 260px;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  justify-content: space-evenly;
}
#bear-plans .item-column .content .check-list {
  flex: 1;
}
#bear-plans .item-column .content p:not(.fine-print),
#bear-plans .item-column .content ul,
#bear-plans .item-column .content li {
  font-size: 15px;
}
#bear-plans .item-column .savings {
  position: absolute;
  right: -10px;
  top: 20px;
  display: flex;
  padding: 20px;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-transform: uppercase;
  background-color: #b42025;
  color: #fff;
  z-index: 5;
  transform: rotate(25deg);
}
#bear-plans h2 {
  margin-bottom: 20px;
  font-weight: 700;
}
#bear-plans p.copy {
  margin-bottom: 15px;
}
#bear-plans .copy {
  order: 2;
}
#bear-plans ul.check-list {
  text-align: left;
  margin: 10px auto 20px;
}
#bear-plans ul.check-list li {
  margin-bottom: 10px;
  width: 260px;
  max-width: 100%;
}
#bear-plans .pricing {
  order: 3;
  margin: 15px 0 10px;
}
#bear-plans .pricing .amount {
  font-size: 1.8em;
  font-weight: 600;
}
#bear-plans .btn {
  width: 100%;
  max-width: 250px;
  display: block;
  align-self: flex-end;
  margin: auto auto 0;
  order: 4;
}
#bear-plans .payment-info {
  padding: 50px 15px;
}
#bear-plans .payment-info img.payment-img {
  width: 100%;
  max-width: 253px;
  display: block;
  margin: 10px auto;
}
#bear-plans .payment-info.free-instead {
  margin: 0 auto;
  max-width: 50%;
  padding-top: 0;
}
#bear-plans .wirecutter {
  margin: 0 auto 100px;
}
#bear-plans .faq-section h2 {
  margin-bottom: 40px;
}
#bear-plans .faq-section .faqs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
#bear-plans .faq-section .faqs .faq {
  width: 45%;
  text-align: left;
  margin-bottom: 40px;
}
#bear-plans .faq-section ul {
  margin-left: 0;
  padding-left: 20px;
}
/* ---------- Mobile Selections ---------- */
.mobile-menu {
  width: 95%;
  max-width: 360px;
  margin: 15px auto 10px;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #6e6e6e;
  display: none;
}
.mobile-menu button {
  width: 33.3333%;
  padding: 10px;
  border-radius: 0;
  border: none;
  background-color: transparent;
  color: #6e6e6e;
  border-right: 1px solid #6e6e6e;
  outline: 0;
}
.mobile-menu button:focus {
  outline: 0;
}
.mobile-menu button:last-of-type:not(.selected) {
  border-right: none;
}
.mobile-menu button.selected {
  background-color: #6e6e6e;
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .top-section.smaller {
    padding-bottom: 0;
  }
  #bear-plans.free-instead .item-column.item-free .content h2 {
    display: block;
  }
  #bear-plans.free-instead .country-india-container .bear-img-wrap {
    box-shadow: none !important;
  }
  #bear-plans.free-instead .country-india-container .inner {
    margin-top: 25px;
    box-shadow: 0 -10px 10px -15px #888;
    border-radius: 0;
  }
  #bear-plans.free-instead .country-india-container .inner .content {
    margin-top: 15px;
  }
  #bear-plans.free-instead .country-india-container .inner h2 {
    margin-bottom: 30px;
  }
  #bear-plans.free-instead .country-india-container .inner .read-more-btn {
    margin-top: 30px;
  }
  #bear-plans {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  #bear-plans .bear-pic {
    height: auto;
  }
  #bear-plans .bear-pic .bear-img-wrap.free {
    position: relative;
    margin-top: 0;
  }
  #bear-plans .bear-pic .bear-img-wrap.free img {
    margin: 0 auto;
    max-width: 210px;
  }
  #bear-plans .bear-pic .bear-img-wrap.free img.large {
    display: none;
  }
  #bear-plans .bear-pic .bear-img-wrap.free img.small {
    display: block;
  }
  #bear-plans .bear-pic .bear-img-wrap.unlimited {
    position: relative;
    margin-top: 0;
  }
  #bear-plans .bear-pic .bear-img-wrap.unlimited img {
    margin-left: auto;
    max-width: 250px;
  }
  #bear-plans .bear-pic .bear-img-wrap.stop-censorship {
    box-shadow: 0 -10px 10px -15px #888 inset;
  }
  #bear-plans .bear-pic .bear-img-wrap.stop-censorship img {
    max-width: 240px;
  }
  #bear-plans .bear-pic .bear-img-wrap.teams {
    position: relative;
    margin-top: 20px;
  }
  #bear-plans .item-column .inner-wrap {
    overflow: visible;
  }
  #bear-plans .item-column.item-free:before {
    display: none;
  }
  #bear-plans .item-column.item-free .bear-pic {
    height: 150px;
    margin-top: -40px;
  }
  #bear-plans .item-column.item-free .inner {
    padding-top: 0;
  }
  #bear-plans .item-column.item-unlimited .inner {
    margin-top: -20px;
    position: relative;
    z-index: 5;
  }
  #bear-plans .item-column.item-unlimited .pricing {
    position: relative;
  }
  #bear-plans .item-column.item-unlimited .pricing.seasonal:after {
    align-items: center;
    background: #a3401d;
    border-radius: 100%;
    box-sizing: border-box;
    color: #fff;
    content: "58% OFF";
    display: flex;
    font-size: 1.15rem;
    font-style: italic;
    font-weight: 900;
    height: 3.6em;
    line-height: 1;
    padding: 0.1em 0 0 0;
    position: absolute;
    right: -0.5em;
    text-align: center;
    top: -2.5em;
    transform: rotate(12deg);
    width: 3.6em;
  }
  #bear-plans .item-column .inner {
    box-shadow: none;
    padding-top: 0;
  }
  #bear-plans .item-column .content {
    padding-top: 0;
  }
  #bear-plans .item-column .content > p {
    order: 3;
  }
  #bear-plans h2 {
    display: none;
  }
  #bear-plans ul.check-list {
    order: 3;
  }
  #bear-plans .pricing {
    order: 2;
    margin-top: 0px;
  }
  #bear-plans .pricing .amount {
    font-size: 1.6em;
  }
  #bear-plans .btn {
    max-width: 200px;
  }
  #bear-plans .payment-info.free-instead {
    max-width: 100%;
  }
  #bear-images .select-free .item-teams,
  #bear-plans .select-free .item-teams {
    display: none;
  }
  #bear-images .select-free .item-unlimited,
  #bear-plans .select-free .item-unlimited {
    display: none;
  }
  #bear-images .select-free p.copy,
  #bear-plans .select-free p.copy {
    margin-top: 15px;
  }
  #bear-images .select-teams .item-free,
  #bear-plans .select-teams .item-free {
    display: none;
  }
  #bear-images .select-teams .item-unlimited,
  #bear-plans .select-teams .item-unlimited {
    display: none;
  }
  #bear-images .select-unlimited .item-free,
  #bear-plans .select-unlimited .item-free {
    display: none;
  }
  #bear-images .select-unlimited .item-teams,
  #bear-plans .select-unlimited .item-teams {
    display: none;
  }
  .mobile-menu {
    display: flex;
  }
}
@media only screen and (min-width: 992px) {
  #bear-plans.free-instead .item-column.item-free .inner {
    margin-left: 15px;
    margin-right: 15px;
    padding-right: 0;
  }
  #bear-plans .item-column.item-free .inner {
    margin-left: 15px;
    margin-right: -15px;
    padding-right: 15px;
  }
  #bear-plans .item-column.item-unlimited .inner {
    margin-right: 15px;
    margin-left: -15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  #bear-plans .faq-section .faqs .faq {
    width: 100%;
  }
}
