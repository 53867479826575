#platforms {
  padding: 0;
}
.platforms {
  text-align: center;
}
.platform-wrap {
  display: inline-block;
  padding: 30px 8px;
  text-decoration: none;
  vertical-align: bottom;
}
.platform-wrap.router-link-active {
  background: #677e57;
}
.platform-wrap p {
  margin: 5px auto 0;
}
.platform {
  width: 50px;
  height: 50px;
  background-image: url("images/apps_platforms_icons@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 30px;
}
.platform.ios {
  background-position: -10px;
}
.platform.android {
  background-position: -70px 0;
}
.platform.mac {
  background-position: -130px 0;
}
.platform.windows {
  background-position: -190px 0;
}
.platform.browser {
  background-position: -250px 0;
}
.platform.blocker {
  background-position: -310px 0;
}
#apps>>> #app-wrap .header {
  font-weight: 600;
  font-size: 1em;
}
#apps>>> #app-wrap #main-cta {
  text-align: center;
}
#apps>>> #app-wrap #main-cta h1 {
  margin-bottom: 40px;
}
#apps>>> #app-wrap #main-cta p {
  max-width: 620px;
  margin: 20px auto;
}
#apps>>> #app-wrap #main-cta img.main-img {
  width: 90%;
  max-width: 659px;
  margin: 0 auto;
}
#apps>>> #app-wrap #main-cta img.main-img.smaller {
  max-width: 500px;
}
#apps>>> #app-wrap #main-cta .download-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  max-width: 800px;
  margin: 40px auto 0;
}
#apps>>> #app-wrap #main-cta .download-row .download-item {
  display: inline-block;
  flex: 1;
  padding: 0 10px;
  max-width: 575px;
  margin-bottom: 15px;
}
#apps>>> #app-wrap #main-cta .download-row .download-item .btn {
  width: 100%;
}
#apps>>> #app-wrap #main-cta .download-row .download-item .fine-print {
  margin: 5px 0 0 0;
  max-width: 100%;
}
#apps>>> #app-wrap .btn-holder {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .platform {
    width: 33px;
    height: 33px;
    background-size: 247px;
    margin: 0 auto;
  }
  .platform.ios {
    background-position: -7px;
  }
  .platform.android {
    background-position: -47px;
  }
  .platform.mac {
    background-position: -87px;
  }
  .platform.windows {
    background-position: -127px;
  }
  .platform.browser {
    background-position: -167px;
  }
  .platform.blocker {
    background-position: -207px;
  }
  #apps>>> #app-wrap #main-cta h1 {
    max-width: 400px;
    margin: 40px auto;
  }
  #apps>>> #app-wrap #main-cta p {
    max-width: 90%;
  }
  #apps>>> #app-wrap #main-cta .download-row {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #apps>>> #app-wrap #main-cta .download-row .download-item {
    width: 100%;
    padding: 0;
  }
  #apps>>> #app-wrap .btn-holder {
    margin-bottom: 15px;
  }
}
