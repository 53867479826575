.box-wrap {
  height: 100%;
}
.box {
  position: relative;
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 30px 15px;
  height: 100%;
  text-align: center;
  display: block;
}
.box p {
  margin: 20px auto 0;
}
.box.mobile {
  display: none;
}
.box.mobile.expanded {
  background-color: #f6f6f6;
}
.box.mobile button.faq-title.plain {
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  padding: 0;
  width: 100%;
  text-decoration: none;
}
.box.mobile button.faq-title.plain:hover,
.box.mobile button.faq-title.plain:focus {
  text-decoration: none;
}
.box.mobile button.faq-title.plain:focus {
  outline: none;
  text-decoration: none;
}
.box.mobile button.faq-title.plain img {
  display: block;
  width: 20px;
  margin-left: 10px;
  transition: transform 0.3s ease-out;
}
.box.mobile button.faq-title.plain img.expanded {
  transform: rotate(45deg);
}
.box.mobile .expand-content img {
  max-width: 270px;
}
.box .slide-fade-enter-active {
  transition: all 0.3s ease;
}
.box .slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.box .slide-fade-enter,
.box .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }
  .box.mobile {
    display: block;
    padding: 12px 15px;
    text-align: left;
    overflow: hidden;
    background-color: #fff;
  }
}
