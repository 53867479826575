#apps {
  padding: 0;
  overflow: hidden;
  min-height: 700px;
  height: 90vh;
  max-height: 750px;
}
#apps .section-inner {
  max-width: 1800px;
  padding: 0;
}
#apps .content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 100%;
}
#apps .content .img-holder {
  flex: 1 1 0;
  position: relative;
  width: 100%;
  max-width: 100%;
  bottom: 0;
}
#apps .content .img-holder img {
  position: absolute;
  bottom: 0;
}
#apps .content .img-holder.apple img.laptop,
#apps .content .img-holder.other img.laptop {
  transition-delay: 0.7s;
}
#apps .content .img-holder.apple img.tablet,
#apps .content .img-holder.other img.tablet {
  transition-delay: 0.4s;
}
#apps .content .img-holder.apple img.mobile,
#apps .content .img-holder.other img.mobile {
  transition-delay: 0.1s;
  z-index: 2;
  bottom: 0;
}
#apps .content .img-holder.apple:first-of-type {
  order: 1;
}
#apps .content .img-holder.apple:last-of-type {
  order: 3;
}
#apps .content .img-holder.apple img.laptop {
  width: 1028px;
  min-width: 1028px;
  right: 0;
  left: auto;
  bottom: 0;
}
#apps .content .img-holder.apple img.tablet {
  width: 594px;
  min-width: 594px;
  right: auto;
  left: 17%;
  bottom: 0;
}
#apps .content .img-holder.apple img.mobile {
  width: 230px;
  min-width: 230px;
  right: auto;
  left: 20px;
}
#apps .content .img-holder.other:first-of-type {
  order: 3;
}
#apps .content .img-holder.other:last-of-type {
  order: 1;
}
#apps .content .img-holder.other img.laptop {
  width: 1093px;
  min-width: 1093px;
  right: auto;
  left: -10%;
  bottom: 0;
}
#apps .content .img-holder.other img.tablet {
  width: 622px;
  min-width: 622px;
  right: 22%;
  left: auto;
  bottom: 0px;
}
#apps .content .img-holder.other img.mobile {
  width: 227px;
  min-width: 227px;
  right: 20px;
}
#apps .element {
  order: 2;
  padding: 90px 0 100px;
  max-width: 390px;
  flex: 1 1 0;
  min-width: 305px;
  margin: auto 0;
}
@media only screen and (max-width: 991px) {
  #apps {
    min-height: 250px;
    height: auto;
    max-height: auto;
  }
}
@media only screen and (max-width: 767px) {
  #apps .content {
    flex-wrap: wrap;
    justify-content: center;
  }
  #apps .content .img-holder {
    order: 2;
    width: 50%;
    max-width: 50%;
    display: flex;
    align-items: flex-end;
  }
  #apps .content .img-holder:first-of-type {
    justify-content: flex-end;
  }
  #apps .content .img-holder img {
    height: auto;
    position: relative;
  }
  #apps .content .img-holder.apple img.laptop,
  #apps .content .img-holder.other img.laptop {
    width: 630px;
    min-width: 630px;
    right: -24%;
    left: auto;
    bottom: -40px;
  }
  #apps .content .img-holder.apple img.tablet,
  #apps .content .img-holder.other img.tablet {
    width: 330px;
    min-width: 330px;
    right: auto;
    left: -15%;
    bottom: -13%;
  }
  #apps .content .img-holder.apple img.mobile,
  #apps .content .img-holder.other img.mobile {
    width: 120px;
    min-width: 120px;
    right: auto;
    left: 0px;
  }
  #apps .content .img-holder.apple:first-of-type {
    order: 2;
  }
  #apps .content .img-holder.apple:last-of-type {
    order: 3;
  }
  #apps .content .img-holder.other:first-of-type {
    order: 2;
  }
  #apps .content .img-holder.other:last-of-type {
    order: 3;
  }
  #apps .element {
    order: 1;
    max-width: none;
    margin: 0 30px;
    display: block;
    padding: 50px 0 10px;
    flex: 1 100%;
  }
  #apps .element .copy {
    max-width: 420px;
    margin: auto;
  }
}
@media only screen and (max-width: 575px) {
  #apps .content .img-holder.apple img.laptop,
  #apps .content .img-holder.other img.laptop {
    width: 470px;
    min-width: 470px;
    right: -40%;
    left: auto;
  }
  #apps .content .img-holder.apple img.tablet,
  #apps .content .img-holder.other img.tablet {
    width: 230px;
    min-width: 230px;
    left: -20%;
  }
  #apps .content .img-holder.apple img.mobile,
  #apps .content .img-holder.other img.mobile {
    width: 90px;
    min-width: 90px;
  }
  #apps .content .img-holder.other img.laptop {
    right: -180px;
    left: auto;
  }
}
